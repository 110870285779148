
import { Component, Vue } from 'nuxt-property-decorator'

@Component({})
export default class extends Vue {
  activeBlock: number = 0

  showBlock (activeIndex: number) {
    if (activeIndex === this.activeBlock) {
      this.activeBlock = 0
      return
    }
    this.activeBlock = activeIndex
  }
}
