
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    idx: {
      type: Number,
      default: 0
    },
    profileImage: {
      type: Number,
      default: 0
    }
  }

  // computed: {
  //   getIcon () {
  //     // @ts-ignore
  //     return this.idx % 2 === 0
  //   }
  // }
}
