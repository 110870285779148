
import { Component, Vue } from 'nuxt-property-decorator'
import { Severity } from '@sentry/types'
import { Page } from '~/types/Pages'
import getShortText from '~/util/short-text.util'

@Component
export default class extends Vue {
  articles: Page[] = []

  async fetch () {
    // @ts-ignore
    this.articles = Object.freeze(await this.$static.getPageList('pages', 3, {
      params: {
        preview: 'true'
      }
    }).then(data =>
      data.map((article: Page) => ({
        alias: article.alias,
        title: getShortText(article.title, 50),
        preview: getShortText(article.preview, 150)
      }))
    ).catch(() => {
      this.$sentry.captureMessage('Ошибка при получении списка статей', Severity.Warning)
    }))
  }

  serverCacheKey () {
    return this.articles[0].alias
  }
}
