
import { Component, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperSlide } from 'swiper-vue2'
import ReviewProfile from '../review/ReviewProfile.vue'
import ReviewStars from '../review/ReviewStars.vue'
import 'swiper/swiper-bundle.css'
@Component({
  components: { ReviewProfile, ReviewStars, Swiper, SwiperSlide }
  })
export default class extends Vue {
  reason: string = '1'
  reviews: any = []
  profileImages: any = [0, 1, 1, 0, 1, 1]

  async fetchReviews () {
    try {
      const { data: reviews } = await this.$axios.$get(process.env.API_ENDPOINT + 'comments', {
        // @ts-ignore
        requireToken: false,
        cache: true,
        params: {
          client_app_id: process.env.APP_ID,
          page: 0,
          page_size: 6
        }
      })

      this.reviews = reviews
    } catch (err) {
      this.$sentry.captureException(err)
    }
  }

  swiper: null

  onSwiper (swiper) {
    this.swiper = swiper
  }

  nextSlide () {
    if (this.swiper) {
      // @ts-ignore
      this.swiper.slideNext()
    }
  }

  mounted () {
    this.fetchReviews()
  }

  prevSlide () {
    if (this.swiper) {
      // @ts-ignore
      this.swiper.slidePrev()
    }
  }
}
