
import { Component, Ref, Vue } from 'nuxt-property-decorator'
import { Severity } from '@sentry/types'
import { Page } from '~/types/Pages'
import getShortText from '~/util/short-text.util'

@Component
export default class extends Vue {
  @Ref('news-block') newsBlockElem!: HTMLDivElement

  news: Page[] = []

  async fetch () {
    // @ts-ignore
    this.news = Object.freeze(await this.$static.getPageList('news', 3, {
      params: {
        preview: 'true'
      }
    }).then(data =>
      data.map((article: Page) => ({
        alias: article.alias,
        title: article.title,
        preview: getShortText(article.preview, 66),
        preview_img: article.preview_img,
        created_at: article.created_at ? article.created_at.split(' ')[0] : ''
      }))
    ).catch(() => {
      this.$sentry.captureMessage('Ошибка при получении списка новостей', Severity.Warning)
    }))
  }
}
