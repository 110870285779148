
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  regions: any = [
    { id: '77', name: 'Москва' },
    { id: '50', name: 'Московская область' },
    { id: '78', name: 'Санкт-петербург' },
    { id: '23', name: 'Краснодар' },
    { id: '66', name: 'Екатеринбург' },
    { id: '74', name: 'Челябинск' },
    { id: '16', name: 'Казань' },
    { id: '24', name: 'Красноярск' },
    { id: '05', name: 'Республика Дагестан' },
    { id: '02', name: 'Республика Башкортостан' },
    { id: '61', name: 'Ростов-на-Дону' },
    { id: '63', name: 'Самара' },
    { id: '52', name: 'Нижний Новгород' },
    { id: '54', name: 'Новосибирск' },
    { id: '59', name: 'Пермский край' }
  ]
}
