
import { Component, Prop } from 'nuxt-property-decorator'
import vSelect from 'vue-select'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import DocPerson from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocPerson'
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import DateField from '~/components/DateField.vue'
import SearchFormOfertaText from '~/components/SearchFormOfertaText.vue'

@Component({
  components: {
  SearchFormOfertaText,
  DocWidgetList,
  DateField,
  vSelect
  }
  })
export default class extends SearchFormAbstract {
  @Prop({
    type: Boolean,
    default: true
  }) showLabel!: boolean

  formData = new DocPerson()
  isPageLoading: boolean = true

  isError: any = {}
  errorDocVal: any = {}

  getType (): string {
    return 'fssp'
  }

  get hasDocs () {
    return this.$store.getters['docs/getDocBySearchType']('fssp').length > 0
  }

  mounted () {
    // @ts-ignore
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.errorDocVal = error.error.response.data
      this.isError = this.errorDocVal
    })

    this.isPageLoading = false
  }
}
