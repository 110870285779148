/**
 * @param {string} longText - default text
 * @param {string} maxLength - quantity symbol
 * @param {string} postfix - postfix
 * @returns {string} - short text
 */
export default function getShortText (longText: string, maxLength: number, postfix: string = '...') {
  let pos
  return ((pos = longText.indexOf(' ', maxLength)) === -1 ? longText : longText.substr(0, pos) + postfix)
}
